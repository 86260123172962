<template>
  <div>
    <div class="display-f justify-end margin-b15" v-if="type === 'project'">
      <a-button type="primary" icon="import" @click="openImport">导入任务模版</a-button>
      <a-button type="primary" class="margin-l20" icon="save" @click="saveShow = true">存储为任务模版</a-button>
    </div>
    <a-card>
      <div ref="gantt" class="gantt-container" />
    </a-card>
    <a-drawer
        title="导入任务模版"
        placement="right"
        :visible="importShow"
        @close="closeImport"
        width="500px"
        class="drawer"
        v-if="importShow"
    >
      <div class="drawer-cot">
        <a-form-model
            ref="form"
            labelAlign="right"
            :label-col="{ span: 7 }"
            :wrapper-col="{ span: 16 }"
            :model="importForm"
            :rules="importRules"
            class="drawer-form"

        >
          <a-row>
            <a-form-model-item label="项目任务模版名称" prop="taskTemplateId">
              <a-select v-model="importForm.taskTemplateId" placeholder="请选择模版">
                <a-select-option :value="item.id" v-for="(item, index) in templateList" :key="item.id">{{ item.templateName }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="项目启动日期" prop="projectStartDate">
              <a-date-picker @change="startDateChange" style="width: 100%"/>
              <div class="font-c9">模版将启动日期，作为起始日期。由此，照模版中的任务时长自动向后排序。</div>
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="check" @click="importSubmit">确定</a-button>
          <a-button icon="close" class="margin-l20" @click="closeImport">取消</a-button>
        </div>
      </div>
    </a-drawer>
    <a-drawer
        title="存储为任务模版"
        placement="right"
        :visible="saveShow"
        @close="closeSave"
        width="500px"
        class="drawer"
        v-if="saveShow"
    >
      <div class="drawer-cot">
        <a-form-model
            ref="form"
            labelAlign="right"
            :label-col="{ span: 7 }"
            :wrapper-col="{ span: 16 }"
            :model="saveForm"
            :rules="saveRules"
            class="drawer-form"
        >
          <a-row>
            <a-form-model-item label="项目任务模版名称" prop="templateName">
              <a-input placeholder="项目任务模版名称" v-model="saveForm.templateName" />
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="check" @click="saveSubmit">确定</a-button>
          <a-button icon="close" class="margin-l20" @click="closeSave">取消</a-button>
        </div>
      </div>
    </a-drawer>
    <a-modal
        :title="detail.taskName"
        :visible="taskShow"
        :confirm-loading="taskLoading"
        :bodyStyle="{'max-height' : '600px', 'overflow-x': 'hidden', 'overflow-y': 'auto'}"
        :width="700"
        @ok="taskSubmit"
        @cancel="closeTask"
    >
      <a-form-model
          ref="infoForm"
          labelAlign="right"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 17 }"
          :model="detail"
      >
        <a-row :gutter="10">
          <a-col span="12">
            <a-form-model-item label="任务状态" prop="taskStatus">
              <div>
                <span v-if="detail.taskStatus === 0">未开始</span>
                <span v-if="detail.taskStatus === 1">审批中</span>
                <span v-if="detail.taskStatus === 2">进行中</span>
                <span v-if="detail.taskStatus === 3">已完成</span>
                <span v-if="detail.taskStatus === 4">已搁置</span>
                <span v-if="detail.taskStatus === 5">已取消</span>
              </div>
            </a-form-model-item>
          </a-col>
          <a-col span="12">
            <a-form-model-item label="计划开始日期" prop="planStartDate">
              <a-date-picker
                  :value="detail.planStartDate ? moment(detail.planStartDate) : null"
                  @change="(date, dateStr) => dateChange(date, dateStr, 'planStartDate')"
                  style="width: 100%"
                  :disabled="!canModify"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="10">
          <a-col span="12">
            <a-form-model-item label="任务类型" prop="taskCategory">
              <div>{{ detail.taskCategory }}</div>
            </a-form-model-item>
          </a-col>
          <a-col span="12">
            <a-form-model-item label="计划结束日期" prop="planEndDate">
              <a-date-picker
                  :value="detail.planEndDate ? moment(detail.planEndDate) : null"
                  @change="(date, dateStr) => dateChange(date, dateStr, 'planEndDate')"
                  style="width: 100%"
                  :disabled="!canModify"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="10">
          <a-col span="12">
            <a-form-model-item label="任务负责人" prop="taskLeaderId">
              <div>{{ detail.taskLeader }}</div>
            </a-form-model-item>
          </a-col>
          <a-col span="12">
            <a-form-model-item label="实际开始日期" prop="startDate">
              <a-date-picker
                  :value="detail.startDate ? moment(detail.startDate) : null"
                  @change="(date, dateStr) => dateChange(date, dateStr, 'startDate')"
                  style="width: 100%"
                  :disabled="!canModify"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="10">
          <a-col span="12">
          </a-col>
          <a-col span="12">
            <a-form-model-item label="实际结束日期" prop="endDate">
              <a-date-picker
                  :value="detail.endDate ? moment(detail.endDate) : null"
                  @change="(date, dateStr) => dateChange(date, dateStr, 'endDate')"
                  style="width: 100%"
                  :disabled="!canModify"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <div class="display-f justify-center padding-t15">
          <a-radio-group v-model="dependencyForm.relationType" @change="getDependencyList">
            <a-radio-button :value="1"><div class="width-150 text-c">前置（{{ dependencyCount1 }}）</div></a-radio-button>
            <a-radio-button :value="2"><div class="width-150 text-c">后置（{{ dependencyCount2 }}）</div></a-radio-button>
          </a-radio-group>
        </div>
        <div class="margin-t30 margin-lr40 padding-lr40" style="height: 150px">
          <template  v-for="(item, index) in dependencyList">
            <div class="display-f justify-between align-items-c" :key="item.id">
              <span>{{ item.taskName }}</span>
              <img src="@/assets/image/common/link.png" alt="" class="cursor" @click="delDependency(item)" v-has-permission="['editTask']">
            </div>
          </template>
        </div>
        <a-row class="margin-t20" v-if="canModify">
          <a-form-model
              ref="dependencyForm"
              labelAlign="right"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 18 }"
              :model="dependencyForm"
              :rules="dependencyRules"
              :hideRequiredMark="true"
          >
            <a-form-model-item label="任务名称" prop="relatedTaskId">
              <a-select v-model="dependencyForm.relatedTaskId" placeholder="请选择任务">
                <a-select-option :value="item.id" v-for="(item) in taskList" :key="item.id">{{ item.taskName }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="依赖关系" prop="dependencyType">
              <a-select v-model="dependencyForm.dependencyType" placeholder="请选择依赖关系">
                <a-select-option :value="1">SS</a-select-option>
                <a-select-option :value="2">FS</a-select-option>
                <a-select-option :value="3">SF</a-select-option>
                <a-select-option :value="4">FF</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-row style="margin-top: 20px">
              <a-form-model-item :wrapper-col="{ span: 20, offset: 2 }">
                <a-button type="primary" block @click="addDependency">确认添加</a-button>
              </a-form-model-item>
            </a-row>
          </a-form-model>
        </a-row>
      </div>
    </a-modal>
  </div>
</template>

<script>
import gantt from 'dhtmlx-gantt'  // 引入模块
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css'
import 'dhtmlx-gantt/codebase/locale/locale_cn'  // 本地化
import { bindTaskMilestone, unbindTaskMilestone, getGanttData, importTaskTemplate, saveTaskTemplate } from '../../api/project/projectDetail'
import { getTemplateList } from '../../api/project/template'
import { createDependency, deleteDependency, getDependencyList, getTaskDetail } from '../../api/task/taskDetail'
import { mapState } from 'vuex'
import moment from 'moment'
import { createTask, getTaskList } from '../../api/task/task'
export default {
  name: 'gantt',
  props: {
    id: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      filterForm: {},
      taskStatusList: ['未开始', '审批中', '进行中', '已完成', '已搁置', '已取消'],
      colorList: ['#c8c8c8', '#4aa4c1', '#03b95c','#ff2800', '#8a8a8a', '#915e18'],
      importShow: false,
      saveShow: false,
      importForm: {
        projectId: this.id
      },
      importRules: {
        taskTemplateId: [
          { required: true, message: '请选择任务模版', trigger: 'change' }
        ],
        projectStartDate: [
          { required: true, message: '请选择项目启动日期', trigger: 'change' }
        ]
      },
      saveForm: {
        projectId: this.id
      },
      saveRules: {
        templateName: [
          { required: true, message: '请输入项目任务模板名称', trigger: 'blur' }
        ],
      },
      templateList: [],
      taskShow: false,
      taskLoading: false,
      detail: {},
      canModify: false,
      dependencyForm: {
        relationType: 1
      },
      dependencyRules: {
        relatedTaskId: [
          { required: true, message: '请选择任务', trigger: 'change' }
        ],
        dependencyType: [
          { required: true, message: '请选择依赖关系', trigger: 'change' }
        ],
      },
      taskList: [],
      dependencyCount1: 0,
      dependencyCount2: 0,
      dependencyList: [],
      dependenciesType: ['', '1', '0', '3', '2'],
      taskEvent: null
    }
  },
  created () {
    gantt.detachEvent(this.taskEvent)
    gantt.clearAll() // 先清空，再添加，就不会有缓存
  },
  beforeDestroy() {
    gantt.detachEvent(this.taskEvent)
  },
  mounted() {
    this.getList()
    this.canModify = this.authority.editTask
  },
  methods: {
    moment,
    getList () {
      if (this.type === 'project') {
        this.filterForm.projectId = this.id
      } else if (this.type === 'milestone') {
        this.filterForm.milestoneId = this.id
      }
      getGanttData(this.filterForm).then(res => {
        let arr = [], links = []
        res.data.forEach(item => {
          arr.push({
            taskName : item.taskName,
            taskStatus : item.taskStatus,
            taskCategory : item.taskCategory,
            taskLeader : item.taskLeader,
            taskLevel : item.taskLevel,
            bindMilestoneStatus : item.bindMilestoneStatus,
            id : item.taskId,
            render : 'split',
          })
          item.taskSchedules.forEach((value, index) => {
            let obj = {}
            obj.id = item.taskId + '-' + index
            obj.parent = item.taskId
            obj.start_date = value.startDate ? this.$moment(value.startDate).format('DD-MM-YYYY') : ''
            obj.duration = value.scheduleype === 5 ? value.durationDays : ((new Date(value.endDate).getTime() - new Date(value.startDate).getTime()) / 86400000 + 1)
            obj.color = this.colorList[value.scheduleype]
            arr.push(obj)
          })
          item.relatedTasks.forEach((value, index) => {
            let obj = {
              id: item.taskId + '_' + index,
              source: item.taskId,
              target: value.relatedTaskId,
              type: this.dependenciesType[value.dependencyType]
            }
            links.push(obj)
          })
        })
        this.list = { data: arr,  links: links }
        this.renderGantt()
      })
    },
    getTemplate () {
      getTemplateList({
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.templateList = res.data.list
      })
    },
    renderGantt () {
      gantt.config.columns = [
        { name: 'taskName', label: '任务名称', align: 'left', width: 200, template:(obj) => {
            let html = '<div class="display-f align-items-c">'
            if (this.type === 'milestone') {
              if (obj.bindMilestoneStatus === 0) {
                html += '<img src="/image/unchecked.png" alt="" class="margin-r5 width-20" id="bind">'
              } else if(obj.bindMilestoneStatus === 1) {
                html += '<img src="/image/checked.png" alt="" class="margin-r5 width-20" id="unbind">'
              } else {
                html += '<img src="/image/checked_other.png" alt="" class="margin-r5 width-20">'
              }
            }
            if (obj.taskLevel === 1) {
              html += '<img src="/image/star_fill.png" alt="" class="margin-r5 width-15">'
            }
            html += '<span>' + obj.taskName + '</span></div>'
            return html
          },
        },
        {
          name: 'taskStatus',
          label: '状态',
          align: 'center',
          template:(obj) => {
            return this.taskStatusList[obj.taskStatus]
          },
        },
        { name: 'taskCategory', label: '任务类型', align: 'center'  },
        { name: 'taskLeader', label: '任务负责人', align: 'center'  }
      ]
      gantt.config.scales = [
        { unit: 'day', step: 1, date: '%M' + '%d' + '日' },
      ];
      gantt.templates.task_text = function(start, end, task) {
        return task.duration + '天'
      }
      gantt.templates.task_class = function(start, end, task){
        return "gantt-task-radius";
      }
      gantt.config.task_height = 25
      this.taskEvent = gantt.attachEvent("onTaskClick",  (id, e) => {
        let taskId = id.split('-')[0]
        if (e.target && e.target.id === 'bind') {
          bindTaskMilestone({
            milestoneId: this.id,
            projectTaskId: taskId
          }).then(() => {
            this.$message.success('绑定成功')
            e.target.src = '/image/checked.png'
            e.target.id = 'unbind'
          })
        } else if (e.target && e.target.id === 'unbind') {
          unbindTaskMilestone({
            milestoneId: this.id,
            projectTaskId: taskId
          }).then(() => {
            this.$message.success('解绑成功')
            e.target.src = '/image/unchecked.png'
            e.target.id = 'bind'
          })
        } else {
          // if (this.type === 'task') {

          getTaskDetail({
            id: taskId
          }).then(res => {
            this.detail = res.data
            this.getTask()
            this.getDependencyList()
            this.getAllDependencyList()
            this.taskShow = true
          })
          // }
        }
      }, {once: true})
      gantt.attachEvent("onTaskDblClick", function(id,e){
        return false;
      })
      gantt.init(this.$refs.gantt)
      gantt.parse(this.list)

    },
    openImport () {
      this.getTemplate()
      this.importShow = true
    },
    closeImport () {
      this.importShow = false
      this.importForm = {
        projectId: this.id
      }
    },
    closeSave () {
      this.saveShow = false
      this.saveForm = { }
    },
    startDateChange (date, dateString) {
      this.importForm.projectStartDate = dateString
    },
    importSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          importTaskTemplate(this.importForm).then(() => {
            this.$message.success('操作成功！')
            this.closeImport()
          })
        }
      })
    },
    saveSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          saveTaskTemplate(this.saveForm).then(() => {
            this.$message.success('操作成功！')
            this.closeSave()
          })
        }
      })
    },
    taskSubmit () {
      this.taskLoading = true
      createTask(this.detail).then(() => {
        this.$message.success('操作成功！')
        this.taskLoading = false
        this.taskShow = false
      })
    },
    closeTask () {
      this.taskShow = false
    },
    dateChange (date, dateString, type) {
      this.detail[type] = dateString
    },
    addDependency () {
      this.dependencyForm.taskId = this.detail.id
      createDependency(this.dependencyForm).then(() => {
        this.getDependencyList()
        this.getAllDependencyList()
        this.dependencyForm = {
          relationType: 1
        }
        this.$message.success('添加成功！')
      })
    },
    delDependency (item) {
      this.$confirm({
        title: '提示',
        content: '确认解除关联？',
        centered: true,
        onOk: () => {
          deleteDependency({
            id: item.id
          }).then(() => {
            this.getDependencyList()
            this.$message.success('已解除关联！')
          })
        }
      })
    },
    getTask () {
      getTaskList({
        queryParam: {},
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.taskList = res.data.list
      })
    },
    getDependencyList () {
      getDependencyList({
        taskId: this.detail.id,
        relationType: this.dependencyForm.relationType
      }).then(res => {
        this.dependencyList = res.data
      })
    },
    getAllDependencyList () {
      this.$util.multipleRequest([
        {url: `/projectTask/listTaskByDependency?relationType=1&taskId=${this.detail.id}`},
        {url: `/projectTask/listTaskByDependency?relationType=2&taskId=${this.detail.id}`}
      ]).then(res => {
        this.dependencyCount1 = res[0].data.length
        this.dependencyCount2 = res[1].data.length
      })
    },
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style lang="scss">
  .gantt-container {
    height: 600px;
  }
  .gantt-task-radius {
    border-radius: 10px !important;
  }
  .gantt_split_parent {
    opacity: 1;
  }
  .gantt_message_area {
    display:  none !important;
  }
</style>
